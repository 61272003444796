<template>
  <div v-if="canAccessBackoffice">
    <slot />
  </div>
  <div v-else>
    <b-card>
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger my-1" />
      <h2 class="text-center text-danger mb-2">
        {{ $t('backoffice.settings.restricted-area') }}
      </h2>
      <img
        :src="imgUrl"
        alt="Error page"
        :style="isSmall ? 'width: 80vw': 'height: 65vh'"
        class="d-block mx-auto"
      >
    </b-card>
  </div>
</template>

<script>
import ErrorPlaceholder from '@/assets/images/placeholders/light/acceso_restringido.svg';
import { startNotifications, sendMixpanelNotification } from '@/@core/utils/notifications/notifications';

export default {
  name: 'BackofficeContainer',
  data() {
    return {
      isSmall: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    imgUrl() {
      return ErrorPlaceholder;
    },
    isStaff() {
      return this.$store.getters.loggedMemberRoles?.find(({ tagName }) => tagName === 'owner') || this.$store.getters.currentCollective.isStaff;
    },
    isStaffOfMain() {
      return this.$store.getters.mainCollective.isStaff;
    },
    canAccessBackoffice() {
      const isStaff = this.isStaff || this.isStaffOfMain;
      if(!isStaff && this.currentCollective.key === '945a43f5-a8bf-4beb-bf8f-f97d5590cb97'){  //gent born
        console.log('redirecting to Gent Born home...');
        window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
          '{subdomain}',
          this.currentCollective.subdomain || 'app',
        ).replace('{slug}', this.currentCollective.slug);
      }
      return isStaff;
    }
  },
  async created() {
    const memberKey = this.$store.getters.loggedMember?.key;
    const communitySlug = this.currentCollective.slug;
    await startNotifications({ memberKey, communitySlug });
    await sendMixpanelNotification('staff-in-backoffice');

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 600;
    },
  },
};
</script>
