<template>
    <b-card class="backoffice-card">
        <!-- Loading -->
        <div v-if="isLoading">
            <b-spinner type="grow" small class="text-primary mx-auto mt-3 d-block" />
        </div>
        <div v-else>
            <!-- banner -->
            <b-row style="margin-bottom: 30px;">
                <b-col cols="12">
                    <div :style="bannerStyle">
                        <h1 class="text-center text-white mt-2 mb-1">
                            App Store
                        </h1>
                        <div class="mx-5 px-25">
                            <h3 class="text-center text-white mx-xl-5 px-lg-5 font-weight-normal">
                                {{ $t('my-nectios.app-store.subtitle') }}
                            </h3>
                        </div>
                        <!-- Search -->
                        <b-form-input
                            v-model="searchInput"
                            type="text"
                            class="mr-2 d-block mx-auto mb-3"
                            style="width:450px; margin-top: 10px;"
                            :placeholder="$t('form-create-item.search-input')"
                            @input="searchItems"
                        />
                    </div>
                </b-col>
            </b-row>
            <!-- Apps container -->
            <div v-if="items && items.length > 0" class="cards-container">
                <!-- app categories -->
                <!--div class="categories-container">
                    <h4 style="margin-bottom: 10px;">
                        Categories
                    </h4>
                    <b-row>
                        <b-col v-for="category in categories" :key="category.key">
                            <small>
                                <span 
                                    class="badge ml-25 badge-primary badge-pill" 
                                    v-bind:style="['background-color', category.color]">
                                    {{ category.name }}
                                </span>
                            </small>
                        </b-col>
                    </b-row>
                </div-->
                <b-row>
                    <!-- Items Loop -->
                    <b-col
                        v-for="item in items"
                        :key="item.key"
                        class="x-scroll-element mx-25 mb-3 p-0 d-flex justify-content-center"
                    >
                        <b-link :to="{ name: 'app', params: { id: item.key } }">
                            <b-card no-body :key="item.key" class="x-scroll-element mb-1 mx-50 p-0 card-blog float-left">
                                <!-- :img-src="getImageSrc(item.imageUrl) || Placeholder" -->
                                <b-card-header class="p-0">
                                    <safe-img
                                    :src="getImageSrc(item.imageUrl)"
                                    :placeholder="Placeholder"
                                    alt="profile photo"
                                    class="card-img"
                                    style="cursor: pointer;"
                                    img-top
                                    />
                                </b-card-header>
                                <div style="padding: 1.5rem; cursor: pointer;">
                                    <h4 v-if="item.name">
                                        {{ item.name[currentLocale] || Object.values(item.name)[0] }}
                                    </h4>
                                    <b-card-text>
                                        <p
                                        v-if="item.tagline"
                                        class="min-height-3 html-text-ellipsis-2 text-secondary"
                                        v-html="item.tagline[currentLocale] || Object.values(item.tagline)[0]"
                                        />
                                        <p
                                        v-else
                                        class="min-height-3 html-text-ellipsis-2 text-secondary"
                                        v-html="item.headline[currentLocale] || Object.values(item.headline)[0]"
                                        />
                                    </b-card-text>
                                </div>
                                <b-card-footer style="margin: 0 15px; padding: 10px;">
                                    <b-row>
                                        <p v-if="item.isIntegration" class="min-height-3 html-text-ellipsis-2 text-secondary">
                                            <small>
                                                <span class="badge ml-25 badge-secondary badge-pill">
                                                    Integration
                                                </span>
                                            </small>
                                        </p>
                                        <p v-if="item.isBeta" class="min-height-3 html-text-ellipsis-2 text-secondary">
                                            <small>
                                                <span class="badge ml-25 badge-primary badge-pill">
                                                    Beta
                                                </span>
                                            </small>
                                        </p>
                                    </b-row>
                                </b-card-footer>
                            </b-card>
                        </b-link>
                    </b-col>
                </b-row>

                <!-- Load More items -->
                <div v-if="items.length > 100" class="w-100 float-left">
                    <b-button
                        v-if="!isLoadingNextPage"
                        class="center-x my-3"
                        variant="outline-primary"
                        @click="handleLoadOfNewItems"
                    >
                        {{ $t('action.load-more') }}
                    </b-button>
                    <div v-else class="w-100">
                        <b-spinner
                        class="center-x my-3"
                        small
                        variant="primary"
                        type="grow"
                        label="Loading..."
                        />
                    </div>
                </div>
            </div>

            <!-- Container without data: Placeholder -->
            <b-row v-else class="horizontal-placeholder">
                <b-col cols="12">
                    <img :src="generalPlaceholder">
                </b-col>
                <b-col cols="12">
                    <p class="text-primary">
                        {{ $t('available.message', { itemName: 'items' }) }}
                    </p>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>
  
  <script>
  import { getImageResource } from '@/@core/utils/image-utils';
    import { quillEditor } from 'vue-quill-editor';
    import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BButton,
    BSpinner,
    BLink,
    BModal,
    BForm,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BFormFile,
    BFormCheckbox,
    } from 'bootstrap-vue';
    import Teleport from 'vue2-teleport';
    import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
    import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
    import SafeImg from '@core/components/safe-img/SafeImg.vue';
    import GeneralPlaceholder from '@/assets/images/placeholders/light/general-placeholder.svg';
    import Banner from '@/assets/images/brand/Banner_dashboard.svg';

export default {
  name: 'IntegrationsList',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BImg,
    BCardText,
    BButton,
    BSpinner,
    Teleport,
    BLink,
    BModal,
    BForm,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    quillEditor,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormFile,
    BFormCheckbox,
    BackofficeContainer,
    SafeImg,
    BBadge,
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      Placeholder,
      searchInput: null,
      results: null,
      loading: false,
      categories: [
            {
                'key':'productivity',
                'name': this.$t('my-nectios.app-store.apps-categories.productivity'),
                "color": "orange"
            },
            {
                'key':'multimedia',
                'name': this.$t('my-nectios.app-store.apps-categories.multimedia'),
                "color": "pink"
            },
            {
                'key':'communication',
                'name':  this.$t('my-nectios.app-store.apps-categories.communication'),
                "color": "blue"
            },
            {
                'key':'integrations',
                'name':  this.$t('my-nectios.app-store.apps-categories.integration'),
                "color": "red"
            },
        ]
    };
  },
  computed: {
    itemsData() {
      return this.$store.getters.appStore;
    },
    itemsSorted() {
      if (!this.itemsData || this.itemsData.length === 0) {
        return [];
      }
      return this.itemsData?.sort((a, b) => (a.name[this.currentLocale] > b.name[this.currentLocale] ? 1 : -1));
    },
    items() {
      return this.searchInput ? this.results : this.itemsSorted;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    generalPlaceholder() {
      return GeneralPlaceholder;
    },
    banner() {
      return Banner;
    },
    bannerStyle() {
      return {
        'background-image': `url(${this.banner})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
        'border-radius': '1.2rem',
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': "center",
        'padding-top': '60px',
        'padding-bottom': '65px',
        'height': '200px',
      };
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      await this.$store.dispatch("getPublicAddons");
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsSorted.filter(
        (item) => item.name[this.currentLocale].toLowerCase().includes(searchTerm)
          || Object.values(item.name)[0]
            .toLowerCase()
            .includes(searchTerm),
      );
    }
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}

.card-img {
  height: 100px !important;
  object-fit: fill !important;
}
.card-blog {
  cursor: default !important;
  height: 270px;
  width: 300px !important;
}
.backoffice-card{
  min-height: 70vh;
}

.categories-container {
    margin: 0 0 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(230, 230, 230);
}
</style>